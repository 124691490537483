<template>
  <article class="login-box card">
    <section class="card-content">
      <h4 class="is-size-3 has-text-weight-bold">{{ $t('AUTH.LOGIN_HEADER') }}</h4>
      <hr />
      <b-field>
        <b-input v-model="email" icon="envelope" :placeholder="$t('APP.EMAIL')" />
      </b-field>
      <br />
      <b-field>
        <b-input
          v-model="password"
          type="password"
          icon="lock"
          :placeholder="$t('AUTH.PASSWORD')"
          @keyup.native.enter="login()"
        />
      </b-field>
      <hr />

      <div v-if="error" class="notification is-danger">{{ $t('AUTH.FAILED_LOGIN') }}</div>

      <button v-if="loggingIn" class="button is-info is-large is-fullwidth" disabled>
        <span class="icon">
          <icon icon="spinner fa-spin" />
        </span>
        <span>{{ $t('AUTH.LOGGING_IN') }}</span>
      </button>
      <button v-else class="button is-primary is-large is-fullwidth" @click="login();">
        <span class="icon">
          <icon icon="sign-in" />
        </span>
        <span>{{ $t('AUTH.LOGIN') }}</span>
      </button>
    </section>
  </article>
</template>

<script>
export default {
  name: 'SlateLoginPage',
  components: {},
  data: () => ({
    email: '',
    password: '',
    loggingIn: false,
    error: false,
  }),
  methods: {
    login() {
      if (!this.email || !this.password) {
        this.error = true;
        return false;
      }
      this.loggingIn = true;
      this.error = false;

      return this.slateAuth
        .login(this.email, this.password)
        .then(() => {
          if (this.$router.currentRoute.query.next) {
            this.$router.push({ path: decodeURIComponent(this.$router.currentRoute.query.next) });
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch((error) => {
          const { status } = error.response;
          if (status === 401 || status === 400) {
            this.loggingIn = false;
            this.error = true;
          }
        });
    },
  },
};
</script>

<style lang="scss">
.login-box {
  margin: 12rem auto;
  width: 75%;
  max-width: 64rem;
}
</style>
